import type { Edges } from "../../types";
import { Link } from "gatsby";
// @flow strict
import React from "react";
import dates from "../../utils/dates";
import styles from "./Feed.module.scss";

type Props = {
  edges: Edges,
};

const Feed = ({ edges }: Props) => (
  <div className={styles["feed"]}>
    {edges.map((edge) => {
      const { date } = edge.node.frontmatter;

      const formattedDate = dates.formatDate(date);

      return (
        <div className={styles["feed__item"]} key={edge.node.fields.slug}>
          <div className={styles["feed__item-meta"]}>
            <time
              className={styles["feed__item-meta-time"]}
              dateTime={new Date(edge.node.frontmatter.date).toISOString()}
            >
              {formattedDate}
            </time>
            <span className={styles["feed__item-meta-divider"]} />
            <span className={styles["feed__item-meta-category-link"]}>
              {edge.node.frontmatter.category}
            </span>
          </div>
          <h2 className={styles["feed__item-title"]}>
            <Link
              className={styles["feed__item-title-link"]}
              to={edge.node.fields.slug}
            >
              {edge.node.frontmatter.title}
            </Link>
          </h2>
          <p className={styles["feed__item-description"]}>
            {edge.node.frontmatter.description}
          </p>
          <Link
            className={styles["feed__item-readmore"]}
            to={edge.node.fields.slug}
          >
            Read
          </Link>
        </div>
      );
    })}
  </div>
);

export default Feed;
